import { React, useEffect } from "react";
import Link from 'next/link'
import { ThemeProvider } from "next-themes";
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreview } from '@prismicio/next'
import { repositoryName } from '../prismicio'
import Script from "next/script"
import { useRouter } from "next/router"
import { trackPageView } from "../utils";

import '../styles/globals.css'

export default function App({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      trackPageView(url, pageProps);
    }

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, []);

  return (
    <ThemeProvider attribute="class">
      <PrismicProvider
        internalLinkComponent={({ href, ...props }) => (
          <Link href={href} {...props} />
        )}
      >
        <PrismicPreview repositoryName={repositoryName}>
          <Component {...pageProps} />
        </PrismicPreview>
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.GTM_CONTAINER_ID}');
        `}
        </Script>
      </PrismicProvider>
    </ThemeProvider>
  )
}
