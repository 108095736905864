const trackPageView = (url) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'virtual_page_view',
    data: {
      url: url
    }
  });
}

const trackFormSubmitEvent = (title, formData) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'form_submit',
    formData: {
      title: title,
      name: formData.data.name.value,
      email: formData.data.emailaddress.value,
    }
  })
}

export {
  trackPageView,
  trackFormSubmitEvent
}
